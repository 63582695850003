var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-items-table-container"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.feeRanges,"select-options":{ enabled: false }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'range')?_c('span',[_c('validation-provider',{attrs:{"name":("range " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("range-" + (props.row.originalIndex) + "}"),"default-value":props.row.range,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                props.row['range'],
                'range',
                props.row.originalIndex
              )}},model:{value:(props.row.range),callback:function ($$v) {_vm.$set(props.row, "range", $$v)},expression:"props.row.range"}})]}}],null,true)})],1):(props.column.field === 'price')?_c('span',[_c('validation-provider',{attrs:{"name":("price " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("price-" + (props.row.originalIndex) + "}"),"default-value":props.row.price,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                props.row['price'],
                'price',
                props.row.originalIndex
              )}},model:{value:(props.row.price),callback:function ($$v) {_vm.$set(props.row, "price", $$v)},expression:"props.row.price"}})]}}],null,true)})],1):(props.column.field === 'action')?_c('span',[(_vm.feeRanges.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteFeeRange(props.row)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e()],1):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("Không có bản ghi nào!")])])]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-list-service",modifiers:{"modal-list-service":true}}],staticClass:"btn-icon ml-auto px-2 my-1",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addFeeRange}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }