var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-items-table-container"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.fees,"select-options":{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',[(props.row.feeObject)?_c('div',[_vm._v(_vm._s(props.row.feeObject.name))]):_vm._e()]):(props.column.field === 'quantity')?_c('span',[(props.row.feeObject && props.row.feeObject.isTuitionFee)?_c('div',[_c('validation-provider',{attrs:{"name":("quantity " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("quantity-" + (props.row.originalIndex) + "}"),"default-value":props.row.quantity,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                  props.row['quantity'],
                  'quantity',
                  props.row.originalIndex
                )}},model:{value:(props.row.quantity),callback:function ($$v) {_vm.$set(props.row, "quantity", $$v)},expression:"props.row.quantity"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":("numberSessons " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("numberSessons-" + (props.row.originalIndex) + "}"),"default-value":props.row.numberSessons,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                  props.row['numberSessons'],
                  'numberSessons',
                  props.row.originalIndex
                )}},model:{value:(props.row.numberSessons),callback:function ($$v) {_vm.$set(props.row, "numberSessons", $$v)},expression:"props.row.numberSessons"}})]}}],null,true)})],1):(
          props.row.feeObject &&
          (props.row.feeObject.feeCalculationType === 'fixed_by_quantity' ||
            props.row.feeObject.feeCalculationType === 'dynamic')
        )?_c('div',[_c('validation-provider',{attrs:{"name":("quantity " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("quantity-" + (props.row.originalIndex) + "}"),"default-value":props.row.quantity,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                  props.row['quantity'],
                  'quantity',
                  props.row.originalIndex
                )}},model:{value:(props.row.quantity),callback:function ($$v) {_vm.$set(props.row, "quantity", $$v)},expression:"props.row.quantity"}})]}}],null,true)})],1):(
          props.row.feeObject &&
          props.row.feeObject.feeCalculationType === 'dynamic'
        )?_c('div',[_vm._v(" "+_vm._s(Number(props.row.quantity).toLocaleString())+" ")]):(props.row.feeObject &&
          props.row.feeObject.feeCalculationType === 'by_meter_reading')?_c('div',[_c('div',[_c('label',{attrs:{"for":("start-quantity-" + (props.row.originalIndex))}},[_vm._v("Số đầu")]),_c('validation-provider',{attrs:{"name":("quantity " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("quantity-" + (props.row.originalIndex) + "}"),"default-value":props.row.startQuantity,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                    props.row['startQuantity'],
                    'startQuantity',
                    props.row.originalIndex
                  )}},model:{value:(props.row.startQuantity),callback:function ($$v) {_vm.$set(props.row, "startQuantity", $$v)},expression:"props.row.startQuantity"}})]}}],null,true)})],1),_c('div',[_c('label',{attrs:{"for":("end-quantity-" + (props.row.originalIndex))}},[_vm._v("Số cuối")]),_c('validation-provider',{attrs:{"name":("quantity " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("quantity-" + (props.row.originalIndex) + "}"),"default-value":props.row.endQuantity,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                    props.row['endQuantity'],
                    'endQuantity',
                    props.row.originalIndex
                  )}},model:{value:(props.row.endQuantity),callback:function ($$v) {_vm.$set(props.row, "endQuantity", $$v)},expression:"props.row.endQuantity"}})]}}],null,true)})],1)]):_vm._e()]):(props.column.field === 'price')?_c('span',[(props.row.feeObject && props.row.feeObject.isTuitionFee)?_c('div',[(_vm.student)?_c('span',[_vm._v(_vm._s(Number(_vm.student.tuitionFee).toLocaleString())+" đ")]):_c('span',{staticClass:"text-danger"},[_vm._v("Chọn học sinh")])]):(
          props.row.feeObject &&
          props.row.feeObject.feeCalculationType === 'dynamic'
        )?_c('div',[_c('validation-provider',{attrs:{"name":("price " + (props.row.originalIndex)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('number-input',{attrs:{"id":("price-" + (props.row.originalIndex) + "}"),"default-value":props.row.price,"error":validationContext.errors[0]},on:{"input":function($event){return _vm.changeCell(
                  props.row['price'],
                  'price',
                  props.row.originalIndex
                )}},model:{value:(props.row.price),callback:function ($$v) {_vm.$set(props.row, "price", $$v)},expression:"props.row.price"}})]}}],null,true)})],1):(
          props.row.feeObject &&
          props.row.feeObject.feeCalculationType === 'fixed_by_quantity'
        )?_c('div',[_vm._v(" "+_vm._s(Number(props.row.price).toLocaleString())+" đ ")]):_vm._e()]):(props.column.field === 'amount')?_c('span',[_vm._v(" "+_vm._s(props.row.amount ? Number(props.row.amount).toLocaleString() : 0)+" đ ")]):(props.column.field === 'amountWithTax')?_c('span',[_vm._v(" "+_vm._s(props.row.amount ? Number(props.row.amountWithTax).toLocaleString() : 0)+" đ ")]):(props.column.field === 'tax')?_c('span',[_vm._v(" "+_vm._s(props.row.tax === -2 ? "không thuế" : props.row.tax === -1 ? "Không tính thuế" : Number(props.row.tax).toLocaleString() + " %")+" ")]):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":"Dịch vụ","hide-edit":true},on:{"on-delete":function($event){return _vm.onDeleteItem(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("Không có bản ghi nào!")])])],2),_c('small',{staticClass:"text-danger pt-2"},[_vm._v(_vm._s(_vm.error))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }