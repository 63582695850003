/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { ref, computed, watch } from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import {
  getUserConfiguration,
  parseDateToString,
} from '@/auth/utils';
import feeStoreModule from '@/views/fee/feeStoreModule';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoiceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }
  if (!store.hasModule('fee')) {
    store.registerModule('fee', feeStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify({ ...props.item, incomeItems: props.item.items.filter(obj => obj.isDiscount === false).map(obj => ({ ...obj, numberSessons: props.item.numberSessons })), discountItems: props.item.items.filter(obj => obj.isDiscount === true).map(obj => ({ ...obj, numberSessons: props.item.numberSessons })) })));
  const total = computed(() => {
    const totalIncome = itemLocal.value.incomeItems.reduce(
      (partialSum, a) => +partialSum
        + Math.abs(a.amount),
      0,
    ) || 0;
    const totalDiscount = itemLocal.value.discountItems.reduce(
      (partialSum, a) => +partialSum
        + Math.abs(a.amount),
      0,
    ) || 0;

    return totalIncome - totalDiscount;
  });
  const totalWithTax = computed(() => {
    const totalIncome = itemLocal.value.incomeItems.reduce(
      (partialSum, a) => +partialSum
        + Math.abs(a.amountWithTax),
      0,
    ) || 0;
    const totalDiscount = itemLocal.value.discountItems.reduce(
      (partialSum, a) => +partialSum
        + Math.abs(a.amountWithTax),
      0,
    ) || 0;

    return totalIncome - totalDiscount;
  });
  const student = computed(() => itemLocal.value.student);
  const school = computed(() => itemLocal.value.school);
  const remainPrepaid = ref(0);
  const enableEditPrepaid = ref(false);
  const userConfiguration = getUserConfiguration();
  const prepaid = computed(() => enableEditPrepaid.value === true ? itemLocal.value.prepaid : Math.min((itemLocal.value.prepaid ?? 0) + remainPrepaid.value, Math.abs(total.value)));
  const remain = computed(() => {
    const prepaidValue = prepaid.value

    const remainValue = Number(totalWithTax.value) - (Number(prepaid.value) ?? 0)
    return remainValue
  });
  const clickCount = ref(0)

  const onAddFee = listServiceSelected => {
    console.log(listServiceSelected);
    for (let index = 0; index < listServiceSelected.length; index++) {
      const fee = listServiceSelected[index];
      if (fee.feeType === 'income_only') {
        if (itemLocal.value.incomeItems.filter(obj => obj.feeId === fee.id).length === 0) {
          const price = fee.feeCalculationType === 'fixed_by_quantity' ? fee.price : fee.isTuitionFee && itemLocal.value.student ? itemLocal.value.student.tuitionFee : 0;
          const amount = fee.defaultValue * price
          const amountWithTax = amount + amount * Math.max(fee.tax, 0) / 100
          const commonItem = {
            feeId: fee.id,
            feeObject: fee,
            isDiscount: false,
            amount,
            tax: fee.tax,
            amountWithTax,
            numberSessons: 0,
          };
          if (fee.feeCalculationType === 'by_meter_reading') {
            itemLocal.value.incomeItems = [
              ...itemLocal.value.incomeItems,
              {
                ...commonItem,
                price: 0,
                quantity: 0,
                startQuantity: 0,
                endQuantity: 0,
              },
            ];
          } else {
            itemLocal.value.incomeItems = [
              ...itemLocal.value.incomeItems,
              {
                ...commonItem,
                price,
                quantity: fee.defaultValue,
              },
            ];
          }
        }
      } else if (fee.feeType === 'discount_only') {
        if (itemLocal.value.discountItems.filter(obj => obj.feeId === fee.id).length === 0) {
          const price = fee.feeCalculationType === 'fixed_by_quantity' ? fee.price : fee.isTuitionFee && itemLocal.value.student ? itemLocal.value.student.tuitionFee : fee.defaultValue;
          const amount = 0
          const amountWithTax = 0.
          const commonItem = {
            feeId: fee.id,
            feeObject: fee,
            isDiscount: true,
            amount: 0,
            tax: fee.tax,
            amountWithTax,
            numberSessons: 0,
          };
          if (fee.feeCalculationType === 'by_meter_reading') {
            itemLocal.value.discountItems = [
              ...itemLocal.value.discountItems,
              {
                ...commonItem,
                price: 0,
                quantity: 0,
                startQuantity: 0,
                endQuantity: 0,
              },
            ];
          } else {
            itemLocal.value.discountItems = [
              ...itemLocal.value.discountItems,
              {
                ...commonItem,
                price,
                quantity: fee.defaultValue,
              },
            ];
          }
        }
      }
    }
  };

  const fetchFees = () => {
    if ((!itemLocal.value.id || itemLocal.value.id <= 0) && itemLocal.value.school) {
      store
        .dispatch('fee/fetchFees', { filter: { schoolId: itemLocal.value.school.id } })
        .then(response => {
          const { items } = response.data;
          itemLocal.value.incomeItems = [];
          itemLocal.value.discountItems = [];
          onAddFee(items);
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const getRemainPrepaidOfStudent = newStudent => {
    if (newStudent) {
      store
        .dispatch('invoice/getRemainPrepaidOfStudent', newStudent.id)
        .then(response => {
          remainPrepaid.value = response.data;
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const filterItems = itemLocal.value.incomeItems.filter(obj => obj.feeObject && obj.feeObject.isTuitionFee);

    let data = {
      month: moment(itemLocal.value.month, "MM-YYYY", true).isValid() ? itemLocal.value.month : moment(itemLocal.value.month).format('MM-YYYY'),
      dueDate: parseDateToString(itemLocal.value.dueDate, "DD-MM-YYYY"),
      numberSessons: filterItems && filterItems.length > 0 ? filterItems[0].numberSessons : itemLocal.value.numberSessons,
      note: itemLocal.value.note,
      prepaid: enableEditPrepaid.value == true ? Number(itemLocal.value.prepaid) : Number(prepaid.value),
      paymentPeriodId: itemLocal.value.paymentPeriod ? itemLocal.value.paymentPeriod.id : null,
      incomeItems: itemLocal.value.incomeItems.map(object => {
        let objectItem = {
          feeId: object.feeId,
          feeObject: object.feeObject,
          price: object.price,
          quantity: object.quantity,
          amount: object.amount,
          name: object.feeObject.name,
          isDiscount: false,
          numberSessons: object.numberSessons,
          tax: object.tax,
          amountWithTax: object.amountWithTax,
          startQuantity: object.startQuantity,
          endQuantity: object.endQuantity
        };
        if (object.id && object.id > 0) {
          objectItem = {
            ...objectItem,
            id: object.id,
          };
        }
        return objectItem;
      }),
      discountItems: itemLocal.value.discountItems.map(object => {
        let objectItem = {
          feeId: object.feeId,
          feeObject: object.feeObject,
          price: object.price,
          quantity: object.quantity,
          amount: object.amount,
          name: object.feeObject.name,
          isDiscount: true,
          numberSessons: object.numberSessons,
          tax: object.tax,
          amountWithTax: object.amountWithTax,
          startQuantity: object.startQuantity,
          endQuantity: object.endQuantity
        };
        if (object.id && object.id > 0) {
          objectItem = {
            ...objectItem,
            id: object.id,
          };
        }
        return objectItem;
      }),
    };

    if (!itemLocal.value.id || itemLocal.value.id <= 0) {
      data = {
        ...data,
        schoolId: itemLocal.value.school.id,
        classId: itemLocal.value.class.id,
        studentId: itemLocal.value.student.id,
      };
      store.dispatch("invoice/createInvoice", data).then(() => { emit('on-item-updated', data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    } else {
      store.dispatch("invoice/updateInvoice", { data, id: itemLocal.value.id }).then(() => { emit('on-item-updated', data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify({ ...props.item, incomeItems: props.item.items.filter(obj => obj.isDiscount === false).map(obj => ({ ...obj, numberSessons: props.item.numberSessons })), discountItems: props.item.items.filter(obj => obj.isDiscount === true).map(obj => ({ ...obj, numberSessons: props.item.numberSessons })) }));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
    fetchFees();
  };

  const removeIncomeItem = index => {
    itemLocal.value.incomeItems.splice(index, 1);
  };

  const removeDiscountItem = index => {
    itemLocal.value.discountItems.splice(index, 1);
  };

  const addIncomeItem = () => {
    itemLocal.value.incomeItems.push({
      id: (itemLocal.value.incomeItems.length + 1) * -1000,
      name: '',
      amount: 0,
    });
  };

  const addDiscountItem = () => {
    itemLocal.value.discountItems.push({
      id: (itemLocal.value.discountItems.length + 1) * -1500,
      name: '',
      amount: 0,
    });
  };


  const setEnablePrepaidEdit = () => {
    clickCount.value += 1
    console.log(clickCount.value)
    if (clickCount.value === 2) {
      enableEditPrepaid.value = true
    } else {
      enableEditPrepaid.value = false
    }
  }

  watch(student, newStudent => {
    console.log(newStudent);
    if (newStudent) {
      getRemainPrepaidOfStudent(newStudent);
    }
    if (newStudent && newStudent.id > 0 && (!itemLocal.value.id || itemLocal.value.id <= 0)) {
      const currentIncomeItems = itemLocal.value.incomeItems;
      for (let index = 0; index < currentIncomeItems.length; index++) {
        if (currentIncomeItems[index].feeObject && currentIncomeItems[index].feeObject.isTuitionFee) {
          currentIncomeItems[index].price = newStudent.tuitionFee;
          if (currentIncomeItems[index].numberSessons > 0) {
            currentIncomeItems[index].amount = Number(currentIncomeItems[index].price) * (Number(currentIncomeItems[index].quantity) / Number(currentIncomeItems[index].numberSessons));
            currentIncomeItems[index].amountWithTax = currentIncomeItems[index].amount + currentIncomeItems[index].amount * Math.max(Number(currentIncomeItems[index].feeObject.tax), 0) / 100
          } else {
            currentIncomeItems[index].amount = 0;
            currentIncomeItems[index].amountWithTax = 0;
          }
        }
      }
      itemLocal.value.incomeItems = [...currentIncomeItems];
    }
  });

  watch(school, newSchool => {
    if (newSchool) {
      fetchFees();
    }
  });

  return {
    refModal,
    refForm,
    itemLocal,
    total,
    totalWithTax,
    isSubmitting,
    prepaid,
    remain,
    removeIncomeItem,
    removeDiscountItem,
    addIncomeItem,
    addDiscountItem,
    resetItemLocal,
    resetModal,
    onSubmit,
    onAddFee,
    enableEditPrepaid,
    setEnablePrepaidEdit
  };
}
