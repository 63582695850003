<template>
  <div class="invoice-items-table-container">
    <vue-good-table :columns="columns" :rows="feeRanges" :select-options="{ enabled: false }">
      <!-- Table rows -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'range'">
          <validation-provider #default="validationContext" :name="`range ${props.row.originalIndex}`" rules="required">
            <number-input :id="`range-${props.row.originalIndex}}`" v-model="props.row.range"
              :default-value="props.row.range" :error="validationContext.errors[0]" @input="
                changeCell(
                  props.row['range'],
                  'range',
                  props.row.originalIndex
                )
                " />
          </validation-provider>
        </span>

        <span v-else-if="props.column.field === 'price'">
          <validation-provider #default="validationContext" :name="`price ${props.row.originalIndex}`" rules="required">
            <number-input :id="`price-${props.row.originalIndex}}`" v-model="props.row.price"
              :default-value="props.row.price" :error="validationContext.errors[0]" @input="
                changeCell(
                  props.row['price'],
                  'price',
                  props.row.originalIndex
                )
                " />
          </validation-provider>
        </span>
        <!-- Hành động -->
        <span v-else-if="props.column.field === 'action'">
          <b-button v-if="feeRanges.length > 1" variant="danger" size="sm" @click="deleteFeeRange(props.row)">
            <feather-icon icon="XIcon" />
          </b-button>
        </span>
      </template>

      <!-- Empty State -->
      <div slot="emptystate">
        <div class="text-center text-muted">Không có bản ghi nào!</div>
      </div>
    </vue-good-table>

    <!-- Nút thêm dòng mới -->
    <b-button v-b-modal.modal-list-service variant="primary" class="btn-icon ml-auto px-2 my-1" size="sm"
      @click="addFeeRange">
      <feather-icon icon="PlusIcon" />
    </b-button>
  </div>
</template>



<script>
import { BSpinner, BButton } from "bootstrap-vue";
import TableActions from "@/views/components/TableActions.vue";
import NumberInput from "@/views/components/NumberInput.vue";
import { VueGoodTable } from "vue-good-table";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider } from "vee-validate";
import { required, double } from "@validations";
import {
  getServiceFeeCalculationType,
  getUserConfiguration,
} from "@/auth/utils";
import { props } from "vue-prism-component";

export default {
  components: {
    BSpinner,
    BButton,
    VueGoodTable,
    TableActions,
    NumberInput,
    ValidationProvider,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      feeRanges: this.rows || [],
      required,
      double,
      userConfiguration: getUserConfiguration(),
    };
  },

  watch: {
    rows(val) {
      this.feeRanges = val;
    },
  },
  mounted() {
    if (this.feeRanges.length === 0) {
      this.addFeeRange();
    }
  },
  methods: {
    addFeeRange() {
      const newIndex = this.feeRanges.length > 0
        ? this.feeRanges[this.feeRanges.length - 1].originalIndex + 1
        : 0;
      this.feeRanges.push({ range: 0, price: 0, originalIndex: newIndex });
      this.updateFeeRanges();
    },
    deleteFeeRange(row) {
      console.log("row", row);
      const index = this.feeRanges.findIndex(
        (item, index) => {
          return index === row.originalIndex;
        }
      );
      if (index > -1) {
        this.feeRanges.splice(index, 1);
        this.updateFeeRanges();
      }
    },
    changeCell(changedData, column, row) {
      if (this.feeRanges[row][column] !== changedData) {
        this.feeRanges[row][column] = changedData;
      }
      this.updateFeeRanges();
    },
    updateFeeRanges() {
      this.$emit("input", this.feeRanges);
    },
  },


  setup() {
    const serviceFeeCalculationType = getServiceFeeCalculationType();

    const { getValidationState } = formValidation();

    // Table Handlers
    const columns = [
      {
        label: "Định mức",
        field: "range",
        sortable: false,
        width: "170px",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        label: "Đơn giá",
        field: "price",
        sortable: false,
        width: "170px",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        field: "action",
        sortable: false,
        width: "50px",
        tdClass: "text-center",
        thClass: "text-center",
      },
    ];

    return {
      columns,
      serviceFeeCalculationType,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.invoice-items-table-container {
  .vgt-table {
    thead {
      th {
        font-size: 0.75rem !important;
        font-weight: 700;
      }
    }

    td {
      font-size: 0.75rem !important;
      font-weight: 500;
    }
  }
}
</style>
