<template>
  <div class="invoice-items-table-container">
    <vue-good-table :columns="columns" :rows="fees" :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }">
      <template slot="loadingContent">
        <b-spinner label="Loading" type="grow" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'">
          <div v-if="props.row.feeObject">{{ props.row.feeObject.name }}</div>
        </span>

        <!-- Column: Quantity -->
        <span v-else-if="props.column.field === 'quantity'">
          <div v-if="props.row.feeObject && props.row.feeObject.isTuitionFee">
            <validation-provider #default="validationContext" :name="`quantity ${props.row.originalIndex}`"
              rules="required">
              <number-input :id="`quantity-${props.row.originalIndex}}`" v-model="props.row.quantity"
                :default-value="props.row.quantity" :error="validationContext.errors[0]" @input="
                  changeCell(
                    props.row['quantity'],
                    'quantity',
                    props.row.originalIndex
                  )
                  " />
            </validation-provider>
            <validation-provider #default="validationContext" :name="`numberSessons ${props.row.originalIndex}`"
              rules="required">
              <number-input :id="`numberSessons-${props.row.originalIndex}}`" v-model="props.row.numberSessons"
                :default-value="props.row.numberSessons" :error="validationContext.errors[0]" @input="
                  changeCell(
                    props.row['numberSessons'],
                    'numberSessons',
                    props.row.originalIndex
                  )
                  " />
            </validation-provider>
          </div>
          <div v-else-if="
            props.row.feeObject &&
            (props.row.feeObject.feeCalculationType === 'fixed_by_quantity' ||
              props.row.feeObject.feeCalculationType === 'dynamic')
          ">
            <validation-provider #default="validationContext" :name="`quantity ${props.row.originalIndex}`"
              rules="required">
              <number-input :id="`quantity-${props.row.originalIndex}}`" v-model="props.row.quantity"
                :default-value="props.row.quantity" :error="validationContext.errors[0]" @input="
                  changeCell(
                    props.row['quantity'],
                    'quantity',
                    props.row.originalIndex
                  )
                  " />
            </validation-provider>
          </div>
          <div v-else-if="
            props.row.feeObject &&
            props.row.feeObject.feeCalculationType === 'dynamic'
          ">
            {{ Number(props.row.quantity).toLocaleString() }}
          </div>
          <div v-else-if="props.row.feeObject &&
            props.row.feeObject.feeCalculationType === 'by_meter_reading'">
            <div>
              <label :for="`start-quantity-${props.row.originalIndex}`">Số đầu</label>
              <validation-provider #default="validationContext" :name="`quantity ${props.row.originalIndex}`"
                rules="required">
                <number-input :id="`quantity-${props.row.originalIndex}}`" v-model="props.row.startQuantity"
                  :default-value="props.row.startQuantity" :error="validationContext.errors[0]" @input="
                    changeCell(
                      props.row['startQuantity'],
                      'startQuantity',
                      props.row.originalIndex
                    )
                    " />
              </validation-provider>
            </div>
            <div>
              <label :for="`end-quantity-${props.row.originalIndex}`">Số cuối</label>
              <validation-provider #default="validationContext" :name="`quantity ${props.row.originalIndex}`"
                rules="required">
                <number-input :id="`quantity-${props.row.originalIndex}}`" v-model="props.row.endQuantity"
                  :default-value="props.row.endQuantity" :error="validationContext.errors[0]" @input="
                    changeCell(
                      props.row['endQuantity'],
                      'endQuantity',
                      props.row.originalIndex
                    )
                    " />
              </validation-provider>
            </div>
          </div>
        </span>
        <!-- Column: Quantity -->
        <span v-else-if="props.column.field === 'price'">
          <div v-if="props.row.feeObject && props.row.feeObject.isTuitionFee">
            <span v-if="student">{{ Number(student.tuitionFee).toLocaleString() }} đ</span>
            <span v-else class="text-danger">Chọn học sinh</span>
          </div>

          <div v-else-if="
            props.row.feeObject &&
            props.row.feeObject.feeCalculationType === 'dynamic'
          ">
            <validation-provider #default="validationContext" :name="`price ${props.row.originalIndex}`"
              rules="required">
              <number-input :id="`price-${props.row.originalIndex}}`" v-model="props.row.price"
                :default-value="props.row.price" :error="validationContext.errors[0]" @input="
                  changeCell(
                    props.row['price'],
                    'price',
                    props.row.originalIndex
                  )
                  " />
            </validation-provider>
          </div>
          <div v-else-if="
            props.row.feeObject &&
            props.row.feeObject.feeCalculationType === 'fixed_by_quantity'
          ">
            {{ Number(props.row.price).toLocaleString() }} đ
          </div>
        </span>

        <!-- Column: Billing Date -->
        <span v-else-if="props.column.field === 'amount'">
          {{ props.row.amount ? Number(props.row.amount).toLocaleString() : 0 }}
          đ
        </span>
        <span v-else-if="props.column.field === 'amountWithTax'">
          {{
            props.row.amount
              ? Number(props.row.amountWithTax).toLocaleString()
              : 0
          }}
          đ
        </span>
        <span v-else-if="props.column.field === 'tax'">
          {{
            props.row.tax === -2
              ? "không thuế"
              : props.row.tax === -1
                ? "Không tính thuế"
                : Number(props.row.tax).toLocaleString() + " %"
          }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <table-actions name="Dịch vụ" :hide-edit="true" @on-delete="onDeleteItem(props.row)" />
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">Không có bản ghi nào!</div>
      </div>
    </vue-good-table>
    <small class="text-danger pt-2">{{ error }}</small>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import TableActions from "@/views/components/TableActions.vue";
import NumberInput from "@/views/components/NumberInput.vue";
import { VueGoodTable } from "vue-good-table";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider } from "vee-validate";
import { required, double } from "@validations";
import {
  getServiceFeeCalculationType,
  getUserConfiguration,
} from "@/auth/utils";

export default {
  components: {
    BSpinner,
    VueGoodTable,
    TableActions,
    NumberInput,
    ValidationProvider,
  },
  props: {
    invoiceId: {
      type: Number,
      default: null,
    },
    student: {
      type: Object,
      default: null,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fees: this.rows,
      required,
      double,
      userConfiguration: getUserConfiguration(),
    };
  },

  watch: {
    rows(val) {
      this.fees = val;
    },
  },
  methods: {
    changeCell(changedData, column, row) {
      if (this.fees[row][column] === changedData) {
        return;
      }

      this.fees[row][column] = changedData;

      console.log(this.fees[row]);

      if (
        this.fees[row] &&
        this.fees[row].feeObject &&
        this.fees[row].feeObject.isTuitionFee
      ) {
        if (this.fees[row].numberSessons && this.fees[row].numberSessons > 0) {
          const totalWithoutTax =
            Number(this.fees[row].price) *
            (Number(this.fees[row].quantity) /
              Number(this.fees[row].numberSessons));
          const totalWithTax =
            totalWithoutTax +
            (totalWithoutTax * Math.max(this.fees[row].tax, 0)) / 100;
          this.fees[row].amount = totalWithoutTax;
          this.fees[row].amountWithTax = totalWithTax;
        } else {
          this.fees[row].amount = 0;
          this.fees[row].amountWithTax = 0;
        }
      }
      else if (this.fees[row] &&
        this.fees[row].feeObject &&
        this.fees[row].feeObject.feeCalculationType === 'by_meter_reading') {
        const startQuantity = Number(this.fees[row].startQuantity);
        const endQuantity = Number(this.fees[row].endQuantity);
        if (startQuantity >= 0 && endQuantity >= 0 && startQuantity <= endQuantity) {
          const quantity = endQuantity - startQuantity;
          this.fees[row].quantity = quantity;
          let amountByMeterReading = 0;
          let remainingQuantity = quantity;
          const feeRanges = JSON.parse(this.fees[row].feeObject.feeRanges);
          for (let i = 0; i < feeRanges.length; i++) {
            const { range, price } = feeRanges[i];

            const applicableQuantity = Math.min(remainingQuantity, range);

            amountByMeterReading += applicableQuantity * price;

            remainingQuantity -= applicableQuantity;

            if (remainingQuantity <= 0) break;

            if (i === feeRanges.length - 1 && remainingQuantity > 0) {
              amountByMeterReading += remainingQuantity * price;
            }
          }
          const amountWithTaxByMeterReading = amountByMeterReading + amountByMeterReading * Math.max(this.fees[row].feeObject.tax, 0) / 100;
          const price = amountByMeterReading / quantity;
          this.fees[row].price = price;
          this.fees[row].amount = amountByMeterReading;
          this.fees[row].amountWithTax = amountWithTaxByMeterReading;
        }
        else {
          this.fees[row].amount = 0;
          this.fees[row].amountWithTax = 0;
          this.fees[row].quantity = 0;
        }
      }
      else {
        this.fees[row].amount =
          Number(this.fees[row].price) * Number(this.fees[row].quantity);
        this.fees[row].amountWithTax =
          this.fees[row].amount +
          (this.fees[row].amount * Math.max(this.fees[row].tax, 0)) / 100;
      }

      this.onChange();
    },

    onDeleteItem(item) {
      if (item.feeId && item.feeId > 0) {
        const remainData = this.fees.filter((fee) => fee.feeId !== item.feeId);
        this.fees = remainData;
      } else {
        const remainData = this.fees.filter(
          (fee) => fee.feeObject.id !== item.feeObject.id
        );
        this.fees = remainData;
      }

      this.onChange();
    },
    onChange() {
      this.$emit("input", this.fees);
    },
  },
  setup() {
    const serviceFeeCalculationType = getServiceFeeCalculationType();

    const { getValidationState } = formValidation();

    // Table Handlers
    const columns = [
      {
        label: "Dịch vụ",
        field: "name",
        sortable: false,
      },

      {
        label: "Đơn giá",
        field: "price",
        sortable: false,
        width: "150px",
        tdClass: "text-center",
        thClass: "text-center",
      },

      {
        label: "Số buổi/Số lượng",
        field: "quantity",
        sortable: false,
        width: "120px",
        tdClass: "text-left",
        thClass: "text-center",
      },

      {
        label: "Thành tiền",
        field: "amount",
        sortable: false,
        width: "120px",
        type: "number",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        label: "VAT",
        field: "tax",
        sortable: false,
        width: "120px",
        type: "number",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        label: "Tổng sau thuế",
        field: "amountWithTax",
        sortable: false,
        width: "120px",
        type: "number",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        label: "",
        field: "action",
        tdClass: "text-center",
        sortable: false,
        width: "40px",
      },
    ];

    return {
      columns,
      serviceFeeCalculationType,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

// .vgt-wrap {
//   .vgt-table {
//     thead {
//       th {
//         font-size: 0.75rem !important;
//         font-weight: 500;
//       }
//     }
//     td {
//       font-size: 0.75rem !important;
//       font-weight: 500;
//     }
//   }
// }
.invoice-items-table-container {
  .vgt-table {
    thead {
      th {
        font-size: 0.75rem !important;
        font-weight: 700;
      }
    }

    td {
      font-size: 0.75rem !important;
      font-weight: 500;
    }
  }
}
</style>
